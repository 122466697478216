import { Grid } from '@mui/material'
import { useAppStore } from '@/hooks/useStore'
import { SlotItem, SlotTimeFontSize, SlotTime } from '@/components/styled/index'
import { calculateBufferCondition } from '@/utils/dates'
import { getSlotDatesOfOtherProduct } from '@/utils/index'
import { ProductKey, Slot } from '../../types/index'
import { SlotGridPrice } from './SlotGridPrice'

interface Props {
  slot: Slot
  isMinGroupSizeSatisfied: boolean
  slotsToSelect: Slot[]
  handleClick: (slot: Slot, slots: Slot[]) => void
  pricePerPerson: number
  groupIsSelected: boolean
  product: ProductKey,
}

export const SlotGridCell = ({
  slot,
  isMinGroupSizeSatisfied,
  slotsToSelect,
  handleClick,
  pricePerPerson,
  groupIsSelected,
  product,
}: Props) => {
  const selectedSlots = useAppStore(state => state.products[product as ProductKey].selectedSlots)
  const selectedProducts = useAppStore(state => state.selectedProducts)
  const products = useAppStore(state => state.products)

  const calendarConfig = useAppStore(state => state.products.DUNGEON.calendarConfig)
  const increasedCapacity = calendarConfig.increasedCapacity === 'active'
  // Check if the other product is selected and if it has any selected slots
  const slotDateOfOtherProduct = getSlotDatesOfOtherProduct(selectedProducts, product, products)
  // If other product has dates selected we want to make unvailable slotCells that don't fulfill the buffer
  const bufferConditionFullfilled = !slotDateOfOtherProduct
    ? true
    : calculateBufferCondition(slot, slotDateOfOtherProduct, increasedCapacity)

  const isFree = isMinGroupSizeSatisfied && slot.free && bufferConditionFullfilled

  const isSelected = (slotId: string) => {
    return selectedSlots.some(selectedSlot => selectedSlot.id === slotId)
  }

  return (
    <Grid item xxs={3} key={slot.id}>
      <SlotItem
        selected={isSelected(slot.id)}
        free={isFree}
        onClick={() => (isFree ? handleClick(slot, slotsToSelect) : null)}
      >
        <SlotTime
          variant="h5"
          gutterBottom
          groupIsSelected={groupIsSelected}
          free={isFree}
          sx={{
            fontSize: SlotTimeFontSize,
          }}
        >
          {slot.time}
        </SlotTime>

        <SlotGridPrice
          pricePerPerson={pricePerPerson}
          groupIsSelected={groupIsSelected}
          isFree={isFree}
        />
      </SlotItem>
    </Grid>
  )
}
