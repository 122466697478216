import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Box } from '@mui/material'
import { ProductKey, ProductName, Slot } from 'types'
import { useTranslations } from 'next-intl'
import SlotGridSkeleton from 'components/skeletons/SlotGridSkeleton'
import { useAppStore } from 'hooks/useStore'
import { ButtonPrimary } from '@/components/reusables/ButtonPrimary'
import { Container } from '@mui/system'
import { BookingStepHeadline } from '@/components/headlines/BookingStepHeadline'
import { BookingMainHeadline } from '@/components/headlines/BookingMainHeadline'
import { slotsAreSelected, updateSelectedSlots } from '@/hooks/utils/storeHelpers'
import { useNotificationToast } from '@/hooks/useNotifications'
import { productsDifferInDate } from '@/utils/index'
import { SlotGrid } from './SlotGrid'
import { useFetchSlots } from '../../hooks/useFetchSlots'
import BookingGroupSize from './BookingGroupSize'
import BookingDatePicker from './BookingDatePicker'
import BookingForm from './BookingForm'
import config from '../../../config'

interface Props {
  nextStep: () => void
  product: ProductName
  combo?: boolean,
  comboCustomForm?: boolean
}

const BookingDateAndGroup = ({ nextStep, product, combo, comboCustomForm }: Props) => {
  const booking = useTranslations('Booking')
  const buttons = useTranslations('Buttons')
  const appState = useAppStore()
  const state = useAppStore(state => state)
  const productConfig = config[product as ProductKey]
  const { selectedProducts, products } = state
  const allowNextStep = slotsAreSelected(selectedProducts, products)


  const { groupSize } = state.products[product as ProductKey]
  const customBooking = groupSize > productConfig.MAX_GROUP_SIZE || combo && groupSize > 5
  const multipleSlots =
    groupSize >= productConfig.MULTIPLE_SLOT_TRESHOLD && groupSize <= productConfig.MAX_GROUP_SIZE
  const displayLanguageInfo = product === 'EXPERIMENT'

  const { freeSlots, loading, fullyBooked, pricing } = useFetchSlots(appState, product)

  useNotificationToast(
    booking('diffDateForProduct'),
    'diffDate',
    productsDifferInDate(products, product) && state.isAdrenalinePack && !customBooking,
    [products[product].datePickerDate]
  )

  useNotificationToast(booking('onlyEnglish'), 'onlyEnglish', displayLanguageInfo, [])

  const handleSlotSelection = (selectedSlot: Slot, availableSlots: Slot[]) =>
    updateSelectedSlots(selectedSlot, availableSlots, multipleSlots, product as ProductKey, selectedProducts, products)

  return (
    <Box marginTop={combo && product === 'EXPERIMENT' ? '20px' : '0'}>
      {/* {JSON.stringify(state.products[product as ProductKey])} */}
      <BookingMainHeadline product={product} comboCustomForm={comboCustomForm} combo={combo}/>
      <BookingGroupSize fullyBooked={fullyBooked} product={product} comboCustomForm={comboCustomForm}/>
      {/* TODO: putt this in a component, StepOne, StepTwo and in StepOne you will have ChooseDate and ChooseGroupSize */}
      <BookingStepHeadline text={booking('chooseDate')} step="2" />
      <BookingDatePicker product={product} loading={loading} />
      {/* eslint-disable-next-line no-nested-ternary */}
      {loading && !customBooking ? (
        <SlotGridSkeleton product={product} />
      ) : customBooking ? (
        <Container maxWidth="sm">
          <BookingForm customBooking />
        </Container>
      ) : (
        <Box>
          <SlotGrid
            slots={freeSlots}
            handleClick={handleSlotSelection}
            fullybooked={fullyBooked}
            pricing={pricing}
            product={product}
          />

          {combo && product === 'EXPERIMENT' ? null : (
            <ButtonPrimary
              text={buttons('next')}
              handleClick={() => (!allowNextStep ? undefined : nextStep())}
              isDisabled={!allowNextStep}
            />
          )}
        </Box>
      )}
    </Box>
  )
}

export default BookingDateAndGroup
