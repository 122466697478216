import { useEffect, useState } from 'react'
import { fetcher } from 'pages/api/fetcher'
import { FreeSlots, ProductKey, Slot } from 'types'
import { useErrorHandler } from 'react-error-boundary'
import { AppState } from './useStore'
import { resetStoreGroupSize, updateStoreCalendarConfig } from './utils/storeHelpers'

// TODO: type the hook
// no need to send here whole formState , date would do enough...

export const useFetchSlots = (state: AppState, product: ProductKey) => {
  const [freeSlots, setFreeSlots] = useState<FreeSlots | []>([])
  const [pricing, setPricing] = useState<[]>([])
  const [loading, setLoading] = useState(false)
  const fullyBooked = freeSlots?.every((slot: Slot) => !slot.free) && freeSlots.length > 0

  const handleError = useErrorHandler()

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        const result = await fetcher('freeSlots', {
          productData: state.products[product],
          product,
          isAdrenalinePack: state.isAdrenalinePack
        })
        setFreeSlots(result.data.freeSlots)
        setPricing(result.data.pricing)

        // This seems not to work
        if (result.data.minGroupSizeFeatureActive) {
          resetStoreGroupSize(product)
        }

        updateStoreCalendarConfig(product, result.data.calendarConfig)
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [state.products[product].datePickerDate])

  return { freeSlots, setFreeSlots, loading, fullyBooked, pricing }
}
