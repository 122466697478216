import { ProductBookingData, ProductKey, Slot } from '@/types/index'
import { datesAreHourApart } from '@/utils/dates'
import { getSlotDatesOfOtherProduct } from '@/utils/index'
import { useAppStore } from '../useStore'

export const resetSelectedSlots = (product: ProductKey) => {
  useAppStore.setState(state => ({
    products: {
      ...state.products,
      [product]: {
        ...state.products[product],
        selectedSlots: [],
      },
    },
  }))
}

export const updateStoreGroupSize = (product: ProductKey, groupSize: number) =>
  useAppStore.setState(state => ({
    products: {
      ...state.products,
      [product]: {
        ...state.products[product],
        groupSize,
      },
    },
  }))

export const resetStoreGroupSize = (product: ProductKey) => {
  useAppStore.setState(state => ({
    products: {
      ...state.products,
      [product]: {
        ...state.products[product],
        groupSize: 0,
      },
    },
  }))
}

export const updateStoreCalendarConfig = (product: ProductKey, calendarConfig: any) => {
  useAppStore.setState(state => ({
    products: {
      ...state.products,
      [product]: {
        ...state.products[product],
        calendarConfig,
      },
    },
  }))
}

export const updateDatePickerDate = (product: ProductKey, newDate: Date) => {
  useAppStore.setState(state => ({
    products: {
      ...state.products,
      [product]: {
        ...state.products[product],
        datePickerDate: newDate,
        // Reset slots if the DatePicker date is changed
        selectedSlots: [],
      },
    },
  }))
}

export const updateSelectedSlots = (
  selectedSlot: Slot,
  availableSlots: Slot[],
  multipleSlots: boolean,
  product: ProductKey,
  selectedProducts: ProductKey[] | [],
  products: Record<ProductKey, ProductBookingData>
) => {
  useAppStore.setState(state => {
    const selectedSlotIndex = availableSlots.indexOf(selectedSlot)
    const nextSlot = selectedSlotIndex + 1
    const prevSlot = selectedSlotIndex - 1

    let selectedSlots =  [selectedSlot]


    // I NEED TO CHECK THIS IF I NEED THIS ACTUALLY
    if (multipleSlots) {
      const nextSlotIsHourApartFromOtherProduct = datesAreHourApart(
        availableSlots[nextSlot].date,
        getSlotDatesOfOtherProduct(selectedProducts, product, products)
      )

      const adjacentAvailableSlot =
        availableSlots[nextSlot].free && nextSlotIsHourApartFromOtherProduct
          ? availableSlots[nextSlot]
          : // If nextSlot is not avaiable, the prevSlot must be available, because there is safeguard in the SlotGridCell component, that ensures one of them must be available for the slotCell to be clickable
            availableSlots[prevSlot]

      selectedSlots = [selectedSlot, adjacentAvailableSlot].sort(
        (firstSlot: Slot, secondSlot: Slot) =>
          // TODO: use lodash?
          Date.parse(firstSlot.date) - Date.parse(secondSlot.date)
      )
    }

    return {
      products: {
        ...state.products,
        [product]: {
          ...state.products[product],
          selectedSlots,
          numberOfSlots: selectedSlots.length,
          startsAt: selectedSlots[0].date,
        },
      },
    }
  })
}

export const slotsAreSelected = (
  selectedProducts: ProductKey[] | [],
  products: Record<ProductKey, ProductBookingData>
) => {
  const [productA, productB] = selectedProducts

  if (selectedProducts.length === 1) {
    return (
      products[productA].selectedSlots.length > 0 || products[productB]?.selectedSlots?.length > 0
    )
  }

  return (
    products[productA].selectedSlots.length > 0 && products[productB]?.selectedSlots?.length > 0
  )
}
